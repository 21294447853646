@import "./variables";

#onetrust-close-btn-container {
    transform: none !important;
    top: 0 !important;
    right: 0 !important;
}

#onetrust-banner-sdk {
    &:not(.ot-iab-2) {
        top: auto !important;
        bottom: 0 !important;
        left: 0 !important;
        max-width: 418px !important;
        box-shadow: 0 0 20px 0 rgb(0, 0, 0 / 50%) !important;
        animation: none !important;

        #onetrust-group-container {
            width: 100% !important;
        }

        #onetrust-policy {
            margin: 30px 10px 15px !important;
        }

        #onetrust-policy-text {
            margin: 0 !important;
            font-family: "Open Sans", Helvetica, Arial, sans-serif !important;
            font-weight: 400 !important;
            font-size: 14px !important;
        }

        #onetrust-button-group-parent {
            position: unset !important;
            width: 100% !important;
            padding: 0 !important;
            transform: none !important;
            top: auto !important;
            left: auto !important;
            margin-bottom: 15px !important;
        }

        #onetrust-button-group {
            margin: 0 !important;
            display: inline-block !important;
        }

        #onetrust-accept-btn-handler {
            margin: 15px 0 !important;
            text-transform: uppercase !important;
            font-family: "Passion One", sans-serif !important;
            font-weight: 700 !important;
            color: #000 !important;
            background-color: #ffd500 !important;
            border: 0 !important;
            padding: 7px 23px !important;
            font-size: 14px !important;
            border-radius: 3px !important;
            line-height: 1.6;
            width: auto;

            &:hover {
                opacity: 0.7 !important;
            }

            &:focus {
                opacity: 1 !important;
            }
        }

        #onetrust-close-btn-container {
            button {
                right: -4px !important;
                top: -4px !important;
                background-size: 15px !important;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 190 190' width='15px' height='15px' fill='%23FFF'%3E%3Cpath d='M190.73,3.33,2.67,188.81m184.48,3L1.41,6.13,6.13,1.41,191.86,187.14ZM1.41,187.14,187.14,1.41l4.72,4.72L6.13,191.86Z' stroke='%23FFF' stroke-miterlimit='10' stroke-width='1'%3E%3C/path%3E%3C/svg%3E") !important;
            }
        }

        .ot-sdk-container {
            width: 100% !important;
        }
    }
}

#onetrust-banner-sdk.ot-iab-2 #onetrust-accept-btn-handler {
    margin: 15px 0 !important;
    text-transform: uppercase !important;
    font-family: "Passion One", sans-serif !important;
    font-weight: 700 !important;
    color: #000 !important;
    background-color: #ffd500 !important;
    border: 0 !important;
    padding: 7px 23px !important;
    font-size: 14px !important;
    border-radius: 3px !important;
    line-height: 1.6;
    width: auto;

    &:hover {
        opacity: 0.7 !important;
    }

    &:focus {
        opacity: 1 !important;
    }
}

#onetrust-pc-sdk {
    border: 0 !important;

    .ot-close-icon {
        background-size: 15px !important;
        position: absolute !important;
        right: 0 !important;
        top: 0 !important;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 190 190' width='15px' height='15px' fill='%23FFF'%3E%3Cpath d='M190.73,3.33,2.67,188.81m184.48,3L1.41,6.13,6.13,1.41,191.86,187.14ZM1.41,187.14,187.14,1.41l4.72,4.72L6.13,191.86Z' stroke='%23FFF' stroke-miterlimit='10' stroke-width='1'%3E%3C/path%3E%3C/svg%3E") !important;
    }

    .ot-pc-header {
        border: 0 !important;
        padding: 20px 0 10px 25px !important;
        background-color: #000;
    }

    .ot-accordion-layout {
        &.ot-cat-item {
            border: 0 !important;

            .ot-acc-grpdesc {
                padding-bottom: 15px !important;
            }
        }

        .ot-acc-hdr {
            padding: 7px 15px 7px 0 !important;
        }

        .ot-vlst-cntr {
            padding-bottom: 10px !important;
        }
    }

    #ot-pc-content {
        padding-top: 20px !important;
        box-sizing: border-box !important;
        padding-right: 0 !important;
        z-index: -1 !important;
    }

    .ot-pc-footer {
        border: 0 !important;

        .ot-btn-container {
            text-align: center !important;
        }
    }

    .ot-cat-header {
        font-weight: 400 !important;
    }

    .ot-plus-minus {
        span:first-of-type {
            width: 1px !important;
        }

        span:last-of-type {
            height: 1px !important;
        }
    }

    .ot-always-active {
        font-weight: 400 !important;
    }
}

#onetrust-consent-sdk #onetrust-pc-sdk {
    h3 {
        text-transform: uppercase !important;
        font-size: 14px !important;
        margin-top: 15px !important;
    }

    #ot-pc-desc {
        font-size: 12px;
        line-height: 18px;
    }

    button:not(#clear-filters-handler):not(.ot-close-icon):not(#filter-btn-handler):not(.ot-remove-objection-handler):not(.ot-obj-leg-btn-handler):not([aria-expanded]):not(.ot-link-btn) {
        margin: 15px 0 !important;
        text-transform: uppercase !important;
        font-family: "Passion One", sans-serif !important;
        color: #000 !important;
        background-color: #ffd500 !important;
        border: 0 !important;
        padding: 11px 23px !important;
        font-size: 14px !important;
        border-radius: 3px !important;
    }
}

@media screen and (min-width: 414px) {
    #onetrust-banner-sdk:not(.ot-iab-2) #onetrust-policy {
        margin: 30px 30px 15px !important;
    }
}
@media screen and (min-width: 768px) {
    #onetrust-banner-sdk:not(.ot-iab-2) {
        bottom: 20px !important;
        left: 20px !important;
    }
}
