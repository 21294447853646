@import "./variables";


.holiday-store-finder {
  margin-bottom: 40px;

  @media (min-width: $lg-min) {
    margin-bottom: 100px;
  }
}

.holiday-store-finder-heading {
  text-align: center;
  color: $white;

  img {
    max-width: 90%;
    margin-bottom: 24px;
  }

  p {
    font-family: $robotoCondensed;
    font-size: 18px;
    line-height: 24px;
  }
}

.holiday-map {
  width: 100%;
  aspect-ratio: .5;
  margin-top: 40px;
  margin-bottom: 40px;

  @media (min-width: $lg-min) {
    margin-top: 80px;
    margin-bottom: 80px;
    aspect-ratio: 1.5;
  }
}
