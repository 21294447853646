@import "./variables";

.intro {
    min-height: 100vh;
    background-image: linear-gradient(to bottom, #1C0502, #610B00);
}

.header {
    padding: 30px 0 25px;
    text-align: center;

    @media (min-width: $sm-min) {
        padding: 40px 0 50px;
    }
}

.logo {
    width: 100%;
    max-width: 62%; // 245px
    margin: 0 auto;

    @media (min-width: $sm-min) {
        max-width: 440px;
    }
}

.row--1 {
    text-align: center;
    position: relative;
}

.intro-heading {
    width: 100%;
    max-width: 90%; // 373px

    @media (min-width: $sm-min) {
        max-width: 75%; // 1200px
    }
}

.intro-product {
    opacity: 0;
    transition: opacity 1s;

    img {
        width: 100%;
        max-width: 100%;
    
        @media (min-width: $sm-min) {
            max-width: 98.5%; // 1576px
        }
    }

    p {
        color: #FFF;
        text-align: center;
        font-family: $robotoCondensed;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        padding: 20px;

        @media (min-width: $sm-min) {
            font-size: 28px;
            line-height: 30px;
        }
    }
}

.intro-product-strip-bags {
    margin-top: -30px;

    @media (min-width: $sm-min) {
        margin-top: -60px;
    }

    @media (min-width: $lg-min) {
        margin-top: -90px;
    }
}

.intro-product-chicken-bites {
    position: absolute;
    top: 30px;
    left: 0;

    @media (min-width: $sm-min) {
        top: 60px;
    }

    @media (min-width: $lg-min) {
        top: 90px;
    }
}

.active-image {
    opacity: 1;
}
.disabled-image {
    opacity: 0;
}

/* ------------ */
.row--2 {
    padding: 0 20px;

    @media (min-width: (1400px + 20px * 2)) {
        padding: 0;
    }

    .limit {
        @media (min-width: $sm-min) {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
    }
}
.container-walmart-button {
    padding: 35px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: $sm-min) {
        width: 33.33%;
        order: 1;
        justify-content: flex-start;
    }
}
.intro-walmart-button {
    &.walmart-button-html {
        background-image: linear-gradient(168deg, #F4E112 40%, #E0301A 115%);
        width: 242px;

        &:hover {
            background-image: linear-gradient(168deg, #F4E112 20%, #E0301A 95%);
        }

        p {
            color: $darkred;
        }
    
        @media (min-width: $sm-min) {
            width: 242px;
        }
    }
}

.container-intro-hashtag {
    padding: 35px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    scroll-behavior: smooth;

    @media (min-width: $sm-min) {
        width: 33.33%;
        order: 3;
        justify-content: flex-end;
    }
}
.intro-hashtag {
    width: 245px;
    height: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 50px;
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.25);
    background-image: linear-gradient(174deg, $red 59%, $darkred 184%);
    text-align: center;

    @media (min-width: $sm-min) {
        width: 320px;
        height: 100px;
    }

    .hashtag-title {
        width: 70%;
    }

    .hashtag-description {
        width: 80%;
    }

    &:hover,
    &:focus {
        background-image: linear-gradient(356deg, $red 40%, $darkred 140%);
    }
}

.container-intro-scroll {
    padding: 35px 0 0;

    @media (min-width: $sm-min) {
        width: 33.33%;
        order: 2;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: center;
    }
}
.intro-scroll {
    position: relative;
    text-align: center;
}
.intro-scroll-title {
    font-family: $robotoCondensed;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.3;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);

    &.title--style1 {
        color: #e0301a;
    }
    &.title--style2 {
        color: #f3e227;
    }

    @media (min-width: $sm-min) {
        font-size: 16px;
    }
}
.intro-arrows {
    width: 20px;
    margin-top: 3vw;

    @media (min-width: $sm-min) {
        width: 20px;
        margin-top: clamp(1px, 1vw, 14px);
    }
}

/* ------------ */
.row--3 {
    padding: 65px 0 25px;
    text-align: center;

    @media (min-width: $sm-min) {
        padding: 115px 0 55px;
    }
}
.heading-packages {
    img {
        width: 100%;
        max-width: 80%; // 373px
        
        @media (min-width: $sm-min) {
            max-width: 50%; // 900px
        }
    }
}

/* ------------ */
.row--4 {
    padding: 0 20px;

    @media (min-width: (1400px + 20px * 2)) {
        padding: 0;
    }

    .limit {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    
        @media (min-width: $sm-min) {
            flex-wrap: nowrap;
        }
    }
}

.container-package-swiper {
    width: 100%;

    @media (min-width: $sm-min) {
        width: 50%;
        order: 2;
    }
}
.package-swiper-wrapper {
    position: relative;

    .swiper-button-prev,
    .swiper-button-next {
        width: Min(10.15%, 42px);
        height: auto;
        aspect-ratio: 1 / 1;
        background-color: #fff;
        border-radius: 50%;

        @media (min-width: $sm-min) {
            width: Min(6.2%, 42px);
        }

        &::after {
            font-size: Min(4vw, 20px);
            color: #000;

            @media (min-width: $sm-min) {
                font-size: Min(1.5vw, 20px);
            }
        }
    }

    .swiper-button-prev {
        left: 0;
    }
    .swiper-button-next {
        right: 0;
    }
}
.package-swiper {
    width: 73.6%;
    
    @media (min-width: $sm-min) {
        width: 73.6%;
    }
}
.package-item {
    max-width: 100%;
}

.container-product-info {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    @media (min-width: $sm-min) {
        width: 25%;
        order: 1;
    }
}
.intro-product-info {
    max-width: Min(95%, 140px);
    
    @media (min-width: $sm-min) {
        max-width: Min(95%, 265px);
    }
}

.container-badge {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    overflow: hidden;

    @media (min-width: $sm-min) {
        width: 25%;
        order: 3;
    }
}
.badge-holder {
    position: relative;
    width: 95px;
    height: 95px;

    @media (min-width: $sm-min) {
        width: 175px;
        height: 175px;
    }
}
.intro-badge-empty {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    animation: rotate 8s linear infinite;
}
.intro-badge-text {
    width: 70%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3D(-50%, -50%, 0);
    z-index: 1;
}

.row--5 {
    padding: 50px 0 75px;
    
    @media (min-width: $sm-min) {
        padding: 50px 0 55px;
    }
}

@keyframes rotate {
    35% {
        transform: rotate(180deg);
    }
    70% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(0deg);
    }
}