@import "./variables";

.video {
    padding: 80px 0 120px;
    position: relative;
    text-align: center;

    @media (min-width: $sm-min) {
        padding: 100px 0 140px;
    }
}
.video-title {
    @media (min-width: $sm-min) {
        padding-top: 0;
    }
}
.video-walmart-button {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 50%);
}
.video-dab-chicken {
    width: 20%;
    margin: 0 auto;
    display: block;
    margin: 8vw auto 0;

    @media (min-width: $sm-min) {
        width: 11%;
        margin-top: clamp(1px, 4vw, 55px);
        margin-bottom: 0;
    }
}
.video-wrap {
    width: 90%;
    position: relative;
    margin: 0 auto;
    background: linear-gradient(to bottom, #f2d726, #e0301a);
    padding: clamp(5px, 0.7vw, 10px);
    margin-top: 8vw;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);

    @media (min-width: $sm-min) {
        width: 60%;
        margin-top: clamp(1px, 4vw, 55px);
        margin-bottom: clamp(1px, 4vw, 55px);
    }
}
.video-wrap-inner {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 56.25%;
    // background: linear-gradient(to bottom, #f2d726, #e0301a);
}
.video-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    // bg duplicated here to prevent chrome 1px issue
    background: linear-gradient(to bottom, #f2d726, #e0301a);

    video {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
}
.video-play-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 5;
    cursor: pointer;
    transform: translate3D(-50%, -50%, 0) scale(0.7);

    @media (min-width: $sm-min) {
        circle,
        path {
            transition: all 0.2s ease-in-out;
        }
    }

    &:hover,
    &:focus {
        circle {
            stroke: #e0301a;
        }
        path {
            fill: #f3e227;
        }
    }
}