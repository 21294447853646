@import "./variables";

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: $robotoCondensed;
  font-weight: 700;
}

img {
  height: auto;
}

p {
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
  color: inherit;
}
