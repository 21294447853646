@import "./variables";


.holiday-slider {
  // min-height: 100vh;
}

.holiday-slider-heading {
  text-align: center;
  color: $white;
  margin-bottom: 40px;

  img {
    max-width: 90%;
    margin-bottom: 24px;
  }

  p {
    font-family: $robotoCondensed;
    font-size: 18px;
    line-height: 24px;
  }
}

.holiday-slider-wrapper {
  position: relative;
  margin-bottom: 40px;

  @media (min-width: $lg-min) {
    margin-bottom: 80px;
  }
}

.holiday-swiper {
  max-width: 1200px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;

  @media (min-width: $lg-min) {
    margin-bottom: 120px;
  }
}

.holiday-swiper-slide {
  text-align: center;
}

.slider-image {
  max-width: 80%;

  @media (min-width: $lg-min) {
    max-width: 100%;
  }
}

.slider-image-blur {
  transform: scale(.8);
  filter: blur(6px);
}

.swiper-button-prev,
.swiper-button-next {
  width: Min(10.15%, 42px);
  height: auto;
  aspect-ratio: 1 / 1;
  background-color: #fff;
  border-radius: 50%;

  @media (min-width: $sm-min) {
    width: Min(6.2%, 42px);
  }

  &::after {
    font-size: Min(4vw, 20px);
    color: #000;

    @media (min-width: $sm-min) {
      font-size: Min(1.5vw, 20px);
    }
  }
}

.swiper-button-prev {
  left: 0;
}
.swiper-button-next {
  right: 0;
}