@import "./variables";

.buy {
    position: relative;
    margin-top: 72px;
    padding: 30px 0 clamp(1px, 10vw, 80px);
    text-align: center;

    @media (min-width: $sm-min) {
        margin-top: 150px;
        padding: 50px 0 clamp(1px, 10vw, 170px);
    }

    .limit--products {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        gap: 4vw;
        padding: 10px 20px 0;

        @media (min-width: $sm-min) {
            grid-template-columns: repeat(4, 1fr);
            padding-top: 45px;
            column-gap: clamp(1px, 2.8vw, 40px);
        }

        @media (min-width: (1400px + 20px * 2)) {
            padding: 0;
        }
    }
}

.holiday-buy {
    padding-bottom: 0;
    margin-bottom: 10px;
    margin-top: 20px;

    @media (min-width: $lg-min) {
        margin-bottom: -20px;
        margin-top: 35px;
    }
}

.holiday-chicken-dab {
    img {
        margin-bottom: 20px;
        max-width: 20%;

        @media (min-width: $sm-min) {
            max-width: 20%;
        }
        @media (min-width: $sm-min) {
            max-width: 10%;
        }
    }
}

.ahc-buy {
    margin-bottom: 24px;
}

.buy-heading {
    width: 85%;
    display: inline-block;

    @media (min-width: $sm-min) {
        width: 100%;
        max-width: 763px;
    }

    img {
        width: 100%;
        max-width: 100%;
    }
}

.holiday-buy-heading {
    max-width: 90%;
}

.buy-chicken-link,
.buy-sauce-link {
    cursor: pointer;
    
    &:hover,
    &:focus {
        text-decoration: none;

        .buy-image {
            box-shadow: 0 0 20px 0 $red;
        }
    }
}

.buy-title {
    height: 4vw;
    width: auto;
    display: block;
    margin: 0 auto;

    @media (min-width: $sm-min) {
        height: clamp(1px, 1.6vw, 23px);
    }
}

.buy-image {
    width: 100%;
    margin-top: 6vw;
    box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.1), 0 0 20px 0 rgba(0, 0, 0, 0.1);

    @media (min-width: $sm-min) {
        margin-top: clamp(1px, 3vw, 40px);
        transition: all 0.2s ease-in-out;
    }
}

.buy-cta {
    font-size: 3.4vw;
    color: $white;
    padding-top: 2vw;

    @media (max-width: ($sm-min - 1px)) {
        min-height: 53px;
    }
    @media (min-width: $sm-min) {
        font-size: clamp(1px, 1vw, 14px);
        padding-top: clamp(1px, 0.5vw, 8px);
    }
}

.pill-button-small {
    width: 100%;
    margin: 4vw auto;
    padding: 3vw 0 2.8vw 0;
    border-radius: 10vw;
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.5);
    display: block;
    color: $brown;
    font-size: 3.4vw;
    background-color: $yellow;
    cursor: pointer;

    @media (min-width: $sm-min) {
        margin-top: clamp(1px, 3vw, 40px);
        margin-bottom: clamp(1px, 3vw, 40px);
        padding: 3vw 0 2.8vw 0;
        padding-top: clamp(1px, 1vw, 15px);
        padding-bottom: clamp(1px, 1vw, 15px);
        font-size: clamp(1px, 1.2vw, 17px);
        transition: all 0.2s ease-in-out;
    }

    span {
        @media (min-width: $sm-min) {
            transition: all 0.2s ease-in-out;
        }
    }
}

.pill-button-style1 {
    span {
        color: $yellow;
    }

    &:hover,
    &:focus {
        text-decoration: none;
        background-color: $red;
    }
}

.pill-button-style2 {
    span {
        color: $darkred;
    }

    &:hover,
    &:focus {
        text-decoration: none;
        background-color: $red;

        span {
            @media (min-width: $sm-min) {
                color: $brown;
            }
        }
    }
}