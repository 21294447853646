// colors
$yellow: #f3e227;
$red: #e0301a;
$brown: #610b00;
$white: #FFF;
$darkred: #BF220D;
$darkblue: #020053;

// fonts
$passion: 'Passion One', cursive;
$robotoCondensed: 'Roboto Condensed', sans-serif;
$roboto: 'Roboto', sans-serif;

$sm-min: 641px;
$lg-min: 1024px;