@import "./variables";

.holiday-party-pack {
  margin-bottom: 40px;

  @media (min-width: $lg-min) {
    margin-bottom: 100px;
  }
}

.holiday-pack-heading {
  text-align: center;
  color: $white;

  img {
    max-width: 90%;
    margin-bottom: 16px;
  }

  p {
    font-family: $robotoCondensed;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 40px;
  }
}

.holiday-pack-content {
  background-image: url('../images/snowflake.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  display: grid;
  grid-gap: 16px;
  margin-bottom: 40px;

  @media (min-width: $lg-min) {
    grid-gap: 40px;
  }

  &__row-1, &__row-2, &__row-3 {
    display: grid;
    grid-gap: 16px;

    @media (min-width: $sm-min) {
      grid-auto-flow: column;
      justify-items: center;
    }
  }
}

.holiday-pack-image-wrapper {
  max-width: 100%;

  img {
    width: 100%;
    height: auto;
    vertical-align: middle;
  }

  @media (min-width: $sm-min) {
    max-width: 250px;
  }

  @media (min-width: $lg-min) {
    max-width: 300px;
    width: 100%;
  }

  &--image-1 {
    justify-self: self-end;
  }

  &--image-2 {
    align-self: self-end;
  }

  &--image-3 {
    justify-self: self-start;
  }

  &--image-4 {
    @media (mid-width: $sm-min) {
      align-self: center;
    }
  }

  &--image-5 {
    max-width: 75%;
    justify-self: center;

    @media (min-width: $sm-min) {
      align-self: center;
    }
  }
}
