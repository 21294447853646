@import "./variables";

.footer {
    background: #1C0502;
    font-family: $roboto;
    color: $yellow;
    text-align: center;
    font-size: 2.5vw;
    padding-bottom: 8vw;
    text-transform: uppercase;
    font-weight: 400;

    @media (min-width: $sm-min) {
        text-align: initial;
        font-size: clamp(1px, 1.3vw, 10px);
        padding-bottom: clamp(1px, 4vw, 55px);
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
    }

    #ot-sdk-btn {
        &.ot-sdk-show-settings,
        &.optanon-show-settings {
            color: inherit;
            border: none;
            background: none;
            padding: 0;
            font-size: 2.5vw;
            font-family: $roboto;
            line-height: 1;
            transition: none;
            text-transform: uppercase;
            text-decoration: underline;
    
            @media (min-width: $sm-min) {
                font-size: clamp(1px, 1.3vw, 10px);
            }
        
            &:hover,
            &:focus {
                background: none;
                text-decoration: none;
                color: inherit;
            }
        }
    }
}

.legal {
    @media (min-width: $sm-min) {
        margin-right: clamp(1px, 6vw, 84px);
    }

    a {
        margin: 0 3vw;
        text-decoration: underline;

        @media (min-width: $sm-min) {
            margin: 0 8px;
        }

        &:last-child {
            @media (min-width: $sm-min) {
                margin-right: 0;
            }
        }

        &:hover,
        &:focus {
            text-decoration: none;
        }
    }
}

.copyright {
    margin-top: 4vw;

    @media (min-width: $sm-min) {
        margin: 0;
        margin-left: clamp(1px, 6vw, 84px);
    }
}