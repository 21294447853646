@import "./variables";

.challenge {
    padding: 110px 0 72px;
    position: relative;
    text-align: center;
    scroll-behavior: smooth;

    @media (min-width: $sm-min) {
        padding: 100px 0 195px;
    }
}
.challenge-insta-wrap {
    padding: 60px 0 0;

    @media (min-width: $sm-min) {
        padding: 105px 0 0;
    }

    .limit {
        padding: 0 20px;

        @media (min-width: (1400px + 20px * 2)) {
            padding: 0;
        }
    }
}

.limit--hashtag {
    padding: 60px 0 0;
    
    @media (min-width: $sm-min) {
        padding: 105px 0 0;
    }
}
.challenge-hashtag {
    font-size: 7vw;
    color: $yellow;
    margin: 0 auto;
    font-family: $passion;
    font-weight: 400;

    @media (min-width: $sm-min) {
        font-size: clamp(1px, 2vw, 30px);
        margin: auto;
    }

    &:hover,
    &:focus {
        text-decoration: underline;
    }

    span {
        color: $red;
    }
}
.eapps-instagram-feed-title-container {
    display: none;
}
.challenge-walmart-button {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 50%);
}
