@import "./variables";

#home {
    background: $brown;
    margin: 0 auto;
    min-height: 100vh;
}

#holiday {
    background: $darkblue;
    margin: 0 auto;
    min-height: 100vh;
    overflow: hidden;
}

.holiday-footer {
    background-image: linear-gradient(to bottom, #020053, #1C0502);
}

.holiday-container {
    max-width: 1300px;
    margin: 0 auto;
    padding: 0 14px;

    @media (min-width: $sm-min) {
        padding: 0;
    }
}

.limit {
    max-width: 1400px;
    margin: 0 auto;
    position: relative;
}

.ahc {
    width: 20%;
    display: block;
    margin: 0 auto;

    @media (min-width: 641px) {
        width: 11%;
        margin: 0;
        position: absolute;
        top: 0;
        left: clamp(1px, 8vw, 100px);
    }
}
.section-title {
    width: 68%;
    display: block;
    margin: 8vw auto 0 auto;

    @media (min-width: 641px) {
        width: 29%;
        margin: 0 auto;
        padding-top: clamp(1px, 1.5vw, 20px);
    }
}

.body-copy {
    color: $white;
    width: 92%;
    font-size: 3.9vw;
    line-height: 1.4;
    margin: 8vw auto 0 auto;

    @media (min-width: 641px) {
        width: 42%;
        font-size: clamp(1px, 1.2vw, 17px);
        margin-top: clamp(1px, 3vw, 50px);
    }

    a {
        color: $yellow;

        @media (min-width: 641px) {
            &:hover {
                text-decoration: underline;
            }
        }
    }
}
.border {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed $red;
}

#watch-and-buy-bg {
    background-image: linear-gradient(to bottom, #610B00, #1C0502);
}

.modal__close-button {
    --outline-color: #e0301a;
    width: 25px;
    aspect-ratio: 1 / 1;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    padding: 0;
    font-size: 0;
    border-radius: 50%;
    border: 2px solid var(--outline-color);
    background: #f2d726;
    cursor: pointer;

    &:hover,
    &:focus {
        filter: invert(10%);
    }

    &:after {
        content: "\274c";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        font-size: 8px; 
        color: var(--outline-color);
        line-height: 22px;
        text-align: center;
    }
}

.holiday-separator-wrapper,.holiday-separator-wrapper-with-chicken {
    text-align: center;
    position: relative;

    img {
        margin: 0;
    }

    &::before, &::after {
        content: "";
        position: absolute;
        top: 50%;
        display: block;
        border-bottom: 1px dashed red;
        transform: translateY(-50%);
        width: 1000px;
    }

    &::before {
        right: calc(100% + 20px);

        @media (min-width: $sm-min) {
            right: calc(100% + 50px);
        }

        @media (min-width: $lg-min) {
            right: calc(100% + 100px);
        }
    }

    &::after {
        left: calc(100% + 20px);

        @media (min-width: $sm-min) {
            left: calc(100% + 50px);
        }

        @media (min-width: $lg-min) {
            left: calc(100% + 100px);
        }
    }
}

.holiday-separator-wrapper-with-chicken {
    &::before,&::after {
        top: 87%;
    }
    &::before {
        right: calc(100% + 10px);
        @media (min-width: $sm-min) {
            right: calc(100% + 20px);
        }

        @media (min-width: $lg-min) {
            right: calc(100% + 30px);
        }
    }
    &::after {
        left: calc(100% + 10px);
        @media (min-width: $sm-min) {
            left: calc(100% + 20px);
        }

        @media (min-width: $lg-min) {
            left: calc(100% + 30px);
        }
    }
}

.holiday-logo-seal {
    width: 90%;
}

.holiday {
    position: relative;
}

.holiday-snowflakes {
    position: fixed;
    pointer-events: none;
    inset: 0;
    overflow: hidden;
    filter: drop-shadow(0 0 10px white);
}

@function random_range($min, $max) {
    $rand: random();
    $random_range: $min + floor($rand * (($max - $min) + 1));
    @return $random_range;
}

.snow {
    $total: 100;
    position: absolute;
    width: 10px;
    height: 10px;
    background: white;
    border-radius: 50%;

    @for $i from 1 through $total {
        $random-x: random(1000000) * 0.0001vw;
        $random-offset: random_range(-100000, 100000) * 0.0001vw;
        $random-x-end: $random-x + $random-offset;
        $random-x-end-yoyo: $random-x + ($random-offset / 2);
        $random-yoyo-time: random_range(30000, 80000) / 100000;
        $random-yoyo-y: $random-yoyo-time * 100vh;
        $random-scale: random(10000) * 0.0001;
        $fall-duration: random_range(10, 30) * 1s;
        $fall-delay: random(30) * -1s;

        &:nth-child(#{$i}) {
        opacity: random(10000) * 0.0001;
        transform: translate($random-x, -10px) scale($random-scale);
        animation: fall-#{$i} $fall-duration $fall-delay linear infinite;
        }

        @keyframes fall-#{$i} {
        #{percentage($random-yoyo-time)} {
            transform: translate($random-x-end, $random-yoyo-y) scale($random-scale);
        }

        to {
            transform: translate($random-x-end-yoyo, 100vh) scale($random-scale);
        }
        }
    }
}
