@import "./variables";


.holiday-intro {
  background-image: linear-gradient(to bottom, #1C0502, #020053);
  margin-bottom: 40px;

  @media (min-width: $lg-min) {
    margin-bottom: 100px;
  }
}

.holiday-header {
  padding: 30px 0 25px;
  text-align: center;

  @media (min-width: $sm-min) {
      padding: 40px 0 50px;
  }
}

.happy-holidays-heading-row {
  text-align: center;
}

.happy-holidays-heading {
  max-width: 90%;

}

.holiday-pack-row {
  position: relative;
  margin: 40px 0;
  text-align: center;
}

.holiday-pack-copy {
  font-size: 18px;
  line-height: 24px;
  color: $white;
}

.holiday-pack-copy-red {
  color: $red;
}

.holiday-pack-image {
  max-width: 100%;

  @media (min-width: $lg-min) {
    max-width: 90%;
    margin-top: -60px;
  }
}

.holiday-badge {
  display: flex;
  justify-content: center;

  @media (min-width: $lg-min) {
    position: absolute;
    right: 0;
    top: 25%;
  }
}
.holiday-badge-holder {
  position: relative;
  width: 95px;
  height: 95px;

  @media (min-width: $sm-min) {
      width: 175px;
      height: 175px;
  }
}
.holiday-intro-badge-empty {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  animation: rotate 8s linear infinite;
}
.holiday-intro-badge-text {
  width: 70%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3D(-50%, -50%, 0);
  z-index: 1;
}

.row--5 {
  padding: 50px 0 75px;
  
  @media (min-width: $sm-min) {
      padding: 50px 0 55px;
  }
}

@keyframes rotate {
  35% {
      transform: rotate(180deg);
  }
  70% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(0deg);
  }
}


.holiday-intro-scroll {
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin-bottom: 14px;
  }
}

.holiday-scroll-title {
  font-family: $robotoCondensed;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.3;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
  color: $red;

  @media (min-width: $sm-min) {
      font-size: 16px;
  }
}
.holiday-intro-arrows {
  width: 20px;
  margin-top: 3vw;

  @media (min-width: $sm-min) {
      width: 20px;
      margin-top: clamp(1px, 1vw, 14px);
  }
}
