@import "./variables";

.watch {
    padding: 72px 0 30px;
    position: relative;
    text-align: center;

    @media (min-width: $sm-min) {
        padding: 190px 0 50px;
    }

    .border {
        @media (min-width: $sm-min) {
            display: none;
        }
    }
}

.limit--heading {
    padding: 0 0 70px;
    
    @media (min-width: $sm-min) {
        padding: 0 0 120px;
    }
}

.watch-title {
    width: 88%;

    @media (min-width: $sm-min) {
        width: 44%;
        padding-top: 0;
    }
}
.latest-episodes-wrap {
    position: relative;
    margin-bottom: 80px;
}
.episodes-title {
    width: 50%;
    position: relative;

    @media (min-width: $sm-min) {
        width: 20%;
    }
}

.limit--youtube-videos {
    padding: 0 20px;

    @media (min-width: (1400px + 20px * 2)) {
        padding: 0;
    }
}
.youtube-videos-wrap {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    column-gap: 4vw;
    row-gap: 6vw;
    padding: 6vw 0;

    @media (min-width: $sm-min) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr;
        column-gap: clamp(1px, 2.8vw, 40px);
        padding-top: clamp(1px, 3vw, 42px);
        padding-bottom: clamp(1px, 3vw, 42px);
    }
}
.video-yt-image {
    width: 100%;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
    aspect-ratio: 16/9;
    object-fit: cover;
    transition: all 0.2s ease-in-out;
}
.video-yt-title {
    font-size: 3.4vw;
    color: #fff;
    margin-top: 1.5vw;

    @media (min-width: 641px) {
        font-size: clamp(1px, 1.2vw, 16px);
        margin-top: clamp(1px, 0.6vw, 10px);
    }
}
.pill-button-watch {
    display: block;
    color: $red;
    font-size: 4vw;
    text-transform: uppercase;
    background: $yellow;
    width: 60%;
    padding: 3vw 0;
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.5);
    border-radius: 10vw;
    margin: 0 auto;
    position: relative;

    @media (min-width: $sm-min) {
        width: 20%;
        padding-top: clamp(1px, 1vw, 15px);
        padding-bottom: clamp(1px, 1vw, 15px);
        border-radius: clamp(1px, 10vw, 50px);
        font-size: clamp(1px, 1.2vw, 17px);
        transition: all 0.2s ease-in-out;
    }

    &:hover,
    &:focus {
        color: $red;
        background-color: $brown;
        text-decoration: none;

        span {
            color: #fff;
        }
    }

    span {
        color: $brown;
    }
}

.video-yt-url {
    &:hover,
    &:focus {
        text-decoration: none;

        .video-yt-image {
            box-shadow: 0 0 20px 0 $red;
        }
    }
}

.watch-walmart-button {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
}
