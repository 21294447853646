@import "./variables";

.walmart-button-html {
    display: inline-block;
    width: 60%;
    padding: 2.1vw;
    z-index: 5;
    border-radius: 10vw;
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.4);
    background-image: linear-gradient(168deg, #e0301a -19%, #610b00 76%);
    line-height: 1;
    text-align: center;
    transition: background-image 0.2s ease-in-out;
    cursor: pointer;

    @media (min-width: 641px) {
        padding: clamp(1px, 1vw, 14px);
        border-width: clamp(2px, 0.3vw, 4px);
        width: 18%;
    }

    &:hover,
    &:focus {
        background-image: linear-gradient(168deg, #610b00 -19%, #e0301a 76%);

        .walmart-button-sub-title {
            color: #610b00;
        }
    }
}
.walmart-button-cta {
    text-transform: uppercase;
    font-size: 4vw;
    color: $yellow;
    font-family: $robotoCondensed;

    @media (min-width: 641px) {
        font-size: clamp(1px, 1.3vw, 19px);
    }
}
.walmart-button-title {
    text-transform: uppercase;
    font-size: 6vw;
    color: $white;
    font-family: $passion;
    font-weight: 400;
    padding: 0.5vw 0;

    @media (min-width: 641px) {
        font-size: clamp(1px, 2.2vw, 30px);
        padding: clamp(1px, 0.5vw, 1px);
    }
}
.walmart-button-sub-title {
    font-size: 3vw;
    color: #e0301a;
    font-weight: 400;
    padding-top: 0.3vw;
    transition: color 0.2s ease-in-out;

    @media (min-width: 641px) {
        font-size: clamp(1px, 1vw, 14px);
        padding-top: clamp(1px, 0.1vw, 2px);
        transition: color 0.2s ease-in-out;
    }
}
